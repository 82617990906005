import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime } from '../../utils';
import Title from '../../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Paper, Box, TextField, Switch, Button, MenuItem } from '@mui/material';
import { client, commande, commandeDetail, priceWeaponCommande } from '../../types';
import AutoCompleteItem from '../../components/AutoCompleteItem';
import CommandeWeapon from '../../components/CommandeWeapon';
const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function CommandeCreate() {
  let navigate = useNavigate()

  const queryParameters = new URLSearchParams(window.location.search)
  const clientID = queryParameters.get("id")
  const [client, setClient] = useState<client>({
    id: 0,
    name: "",
    number: "",
    type: ""
  });

  const [weaponList, setWeaponList] = useState<priceWeaponCommande[]>([])

  const [ newItem, setNewItem] = useState<number>(0)

  const [loading, setLoading] = useState(true);

  const [commandDetailBase, setCommandDetail] = useState<commandeDetail[]>([])
  const [commandeBase, setCommande] = useState<commande>({
    id: 0,
    clientID: parseInt(clientID || "0"),
    isReduction: false,
    delivered: false,
    price: 0,
    priceBase: 0,
    reduction: 0,
    userID: 0,
    createdAt: new Date(),
    ready: false,

  })

  useEffect(() => {
    getClient()
    getWeaponList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])




  const getClient = async () => {
    if (!clientID) return navigate("/client")
    if (Number.isNaN(parseInt(clientID))) return navigate("/client")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/get', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ clientID: parseInt(clientID) })
      })

      const data: client = await response.json();
      return setClient(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getWeaponList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/sell/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: priceWeaponCommande[] = await response.json();

      let commandDetailTemp: commandeDetail[] = []
      let tempWeaponList : priceWeaponCommande[] = []

      for (let i = 0; i < data.length; i++) {
        if (data[i].alwaysShow) {

          commandDetailTemp.push({
            quantity: 0,
            itemID: data[i].itemID,
            isReduction: true,
            price: data[i].priceSale,
            reduction: 0,
            commandeID: 0,
            id: 0,
            weapon: data[i],
            quantityReady: 0
          })
        } else tempWeaponList.push(data[i])
      }

      setCommandDetail(commandDetailTemp)
      setWeaponList(tempWeaponList)
      setNewItem(tempWeaponList[0].itemID)

      setLoading(false)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const addCommande= async (commande: commande, commandDetail:commandeDetail[]) => {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/create', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({commande, commandDetail})
      })
      return navigate("/weapon/commande/list")
  }

  
  if(loading) return null
  return (
    <CommandeWeapon add={true} addCommande={addCommande} client={client} commandBase={commandeBase} newItemBase={newItem} commandDetailBase={commandDetailBase} weaponListBase={weaponList}/>
  );
}

export default CommandeCreate;
