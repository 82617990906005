import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime } from '../../utils';
import Title from '../../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Paper, Box, TextField, Switch, Button, MenuItem } from '@mui/material';
import { client, commande, commandeDetail, priceWeaponCommande } from '../../types';
import AutoCompleteItem from '../../components/AutoCompleteItem';
import CommandeWeapon from '../../components/CommandeWeapon';
const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function CommandeEdit() {
  let navigate = useNavigate()

  const queryParameters = new URLSearchParams(window.location.search)
  const commandeID = queryParameters.get("id")

    const [client, setClient] = useState<client>({
      id: 0,
      name: "",
      number: "",
      type: ""
    });

  const [weaponList, setWeaponList] = useState<priceWeaponCommande[]>([])

  const [newItem, setNewItem] = useState<number>(0)

  const [commandDetailBase, setCommandDetail] = useState<commandeDetail[]>([])
  const [commandeBase, setCommande] = useState<commande>({
    id: parseInt(commandeID || "0"),
    clientID: 0,
    isReduction: false,
    delivered: false,
    price: 0,
    priceBase: 0,
    reduction: 0,
    userID: 0,
    createdAt: new Date(),
    ready: false,

  })

  const [loading, setLoading] = useState(true);


  useEffect(() => {
    initialiseCommand()
    // getCommandeDetail()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])



  const initialiseCommand = async () => {
    if (!commandeID) return navigate("/weapon/commande/list")
    if (Number.isNaN(parseInt(commandeID))) return navigate("/weapon/commande/list")
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/sell/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const dataWeaponList: priceWeaponCommande[] = await response.json();

      const responseCommand = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/detail', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify({ commandeID: parseInt(commandeID) })
      })

      const data: commande = await responseCommand.json();

      setCommande({
        id: data.id,
        clientID: data.clientID,
        isReduction: data.isReduction,
        delivered: data.delivered,
        price: data.price,
        priceBase: data.priceBase,
        reduction: data.reduction,
        userID: data.userID,
        createdAt: data.createdAt,
        ready: data.ready
      })

      const commandeDetailData = data.commandeDetail

      if (data.client) {
        setClient(data.client)
      }

      let commandDetailTemp: commandeDetail[] = []
      let tempWeaponList: priceWeaponCommande[] = []
      // if(!commandeDetailData) return

      for (let i = 0; i < dataWeaponList.length; i++) {
        const commandeItem = commandeDetailData?.find(detail => detail.itemID === dataWeaponList[i].itemID)
        if(commandeItem) {
          commandDetailTemp.push({
            quantity: commandeItem.quantity,
            itemID: commandeItem.itemID,
            commandeID: commandeItem.commandeID,
            id: commandeItem.id,
            quantityReady: 0,
            isReduction: commandeItem.isReduction,
            price: commandeItem.price,
            reduction: commandeItem.reduction,
            weapon: dataWeaponList[i]
          })
          continue
        }
        if (dataWeaponList[i].alwaysShow) {

          commandDetailTemp.push({
            quantity: 0,
            itemID: dataWeaponList[i].itemID,
            isReduction: true,
            price: dataWeaponList[i].priceSale,
            reduction: 0,
            commandeID: 0,
            id: 0,
            weapon: dataWeaponList[i],
            quantityReady: 0
          })
        } else tempWeaponList.push(dataWeaponList[i])
      }

      setWeaponList(tempWeaponList)
      setNewItem(tempWeaponList[0].itemID)
      
      
      
      // for (let i = 0; i < commandDetailTemp.length; i++) {
      //   const commandeItem = commandeDetailData.find(detail => detail.itemID === commandDetailTemp[i].itemID)
      //   if(!commandeItem) continue
      //   commandDetailTemp[i].commandeID = commandeItem.commandeID
      //   commandDetailTemp[i].id = commandeItem.id
      //   commandDetailTemp[i].price = commandeItem.price
      //   commandDetailTemp[i].reduction = commandeItem.reduction
      //   commandDetailTemp[i].quantity = commandeItem.quantity
        
      // }
      setCommandDetail(commandDetailTemp)

      setLoading(false)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }



  const addCommande = async (commande: commande, commandDetail: commandeDetail[]) => {
    const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/edit', {
      headers: {
        "Content-Type": "application/json",
      },
      mode: 'cors',
      method: "POST",
      credentials: "include",
      body: JSON.stringify({ commande, commandDetail })
    })

    return navigate("/weapon/commande/list")
  }

  if(loading) return null
  return (
    <CommandeWeapon add={false} addCommande={addCommande} client={client} commandBase={commandeBase} newItemBase={newItem} commandDetailBase={commandDetailBase} weaponListBase={weaponList}/>
  );
}

export default CommandeEdit;
