import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { intervalTime } from '../utils';
import Title from '../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Box, Paper, TextField, MenuItem, Button } from '@mui/material';
import { client, commande, commandeDetail, priceWeaponCommande } from '../types';

const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function CommandeWeapon({ client, commandDetailBase, weaponListBase, newItemBase, commandBase, addCommande, add }: 
  { client: client, commandDetailBase: commandeDetail[], weaponListBase: priceWeaponCommande[], newItemBase: number, commandBase: commande, addCommande: (commande:commande, commandeDetail:commandeDetail[]) => Promise<void>, add: boolean }) {
  let navigate = useNavigate()


  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const [newItem, setNewItem] = useState<number>(newItemBase)

  const [commandDetail, setCommandDetail] = useState<commandeDetail[]>(commandDetailBase)
  const [weaponList, setWeaponList] = useState<priceWeaponCommande[]>(weaponListBase)
  const [commande, setCommande] = useState<commande>(commandBase)




  useEffect(() => {
    calculateTotalPrice()
  }, [commandDetail])

  const calculateTotalPrice = () => {
    let totalPrice = 0
    commandDetail?.forEach(command => {
      // if(command.quantity === 0) return
      totalPrice += command.price * command.quantity
    })
    setCommande({ ...commande, priceBase: totalPrice, price: totalPrice * (1 - commande.reduction / 100) })
  }

  const changeQuantity = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setCommandDetail(tl => {
      const list = tl.map((command) => {
        if (command.itemID === id) {
          command.quantity = parseInt(e.target.value)
        }
        return command
      })
      return list
    })
    // calculateTotalPrice()
  }
  const changePrice = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, id: number) => {
    setCommandDetail(tl => {
      const list = tl.map((command) => {
        if (command.itemID === id) {
          command.price = parseInt(e.target.value)
        }
        return command
      })
      return list
    })
    // calculateTotalPrice()
  }


  const calculateBrutePrice = () => {
    let brutePrice = 0
    commandDetail?.forEach(command => {

      if (command.quantity === 0) return
      let cost = command.weapon.priceBuy || 0
      if (command.weapon.craftable) {
        const recipe = command.weapon.recipe?.recipeWeapon
        cost = 0
        if (!recipe) return
        for (let i = 0; i < recipe?.length; i++) {
          const pricePiece = recipe[i].item.priceItemWeapon[0].price
          cost += pricePiece || 0
        }
      }
      brutePrice += cost * command.quantity
    })
    return brutePrice
  }


  const addItem = () => {
    let transacItem: priceWeaponCommande
    if (!weaponList) return

    for (let i = 0; i < weaponList.length; i++) {
      if (newItem === weaponList[i].itemID) {
        transacItem = weaponList[i]
        break
      }
    }

    setCommandDetail(prev => [
      ...prev,
      {
        quantity: 0,
        itemID: transacItem.itemID,
        isReduction: true,
        price: transacItem.priceSale,
        reduction: 0,
        commandeID: 0,
        id: 0,
        weapon: transacItem,
        quantityReady: 0
      }
    ]
    )
    const tempWeaponList = weaponList?.filter(weapon => {
      if (transacItem.itemID === weapon.itemID) return
      return weapon
    })
    setWeaponList(tempWeaponList)
    setNewItem(tempWeaponList[0]?.itemID || 0)

  }
  return (
    <>
      <Title text={`${add ? "Nouvelle" : "Edition"} Commande d'arme pour ${client?.name} ${client?.type === "PM" ? `(${client.number})` : ""}`} />


      <Box sx={{ p: 2, display: 'flex', flexWrap: "wrap", flexDirection: "row", margin: '1rem', justifyContent: "center" }}>
        {
          commandDetail?.map(commandItem => {
            let cost = commandItem.weapon.priceBuy || 0
            if (commandItem.weapon.craftable) {
              const recipe = commandItem.weapon.recipe?.recipeWeapon
              cost = 0
              if (!recipe) return
              for (let i = 0; i < recipe?.length; i++) {
                const pricePiece = recipe[i].item.priceItemWeapon[0].price
                cost += pricePiece || 0
              }
            }

            return (
              <Paper elevation={16} sx={{ p: 2, margin: "1rem", width: "23%", display: 'flex', flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
                <Box sx={{ width: "100%" }}>
                  <Title text={commandItem.weapon.item.name} variant='h5' />
                </Box>
                {/* <Title text={'Prix d\'achat : ' + cost} variant='h5' /> */}
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label={`Prix ${commandItem.weapon.craftable ? "de fabrication" : "d'achat"}`} variant='outlined' value={cost} />
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label={`Prix Brute`} variant='outlined' value={cost * commandItem.quantity} />

                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' label="Prix de vente" variant='outlined' value={commandItem.price}  onChange={(e) => { changePrice(e, commandItem.itemID) }}/>
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Prix de vente Base" variant='outlined' value={commandItem.weapon.priceSale} />
                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' label="Quantité" variant='outlined' value={commandItem.quantity} onChange={(e) => { changeQuantity(e, commandItem.itemID) }} />

                {/* <TextField sx={{ margin: "1rem", width: "30%" }} type='number' disabled={commandItem.isReduction} label="Reduction (%)" variant='outlined' value={commandItem.reduction} onChange={(e) => { changeReduction(e, commandItem.itemID) }} />
              <Switch sx={{ alignSelf: "center" }} value={commandItem.isReduction} onChange={(e) => { changeIsReduction(e, commandItem.itemID) }} ></Switch> */}

                <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Prix totale" variant='outlined' value={commandItem.price * commandItem.quantity} />


              </Paper>
            )
          })
        }


        {weaponList?.length !== 0 &&
          <Box sx={{ width: "100%", justifyItems: "center" }}>
            <Paper elevation={16} sx={{ p: 2, margin: "1rem", width: "20%", display: 'flex', flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>


              <TextField id='addItem' select label="Quoi ?" variant='outlined' value={newItem}
                onChange={(e) => { setNewItem(parseInt(e.target.value)) }}
                sx={{ margin: "1rem" }}
                InputProps={{ sx: { color: weaponList?.filter(item => item.id === newItem).map(o => o.item.color) } }}
                SelectProps={{ MenuProps: { sx: { maxHeight: 500 } } }}>
                {weaponList?.map((option) => (
                  <MenuItem key={option.item.name} value={option.itemID} sx={{ color: option.item.color }}>
                    {option.item.name}
                  </MenuItem>
                ))}
              </TextField>
              <Button variant='outlined' color='success' onClick={addItem}>Ajouter</Button>


            </Paper>
          </Box>
        }


        <Box sx={{ width: "100%", justifyItems: "center" }}>

          <Paper elevation={16} sx={{ p: 2, margin: "1rem", width: "50%", display: 'flex', flexWrap: "wrap", flexDirection: "row", justifyContent: "center" }}>
            <Box sx={{ width: "100%" }}>
              <Title text='Totale' variant='h5' />
            </Box>
            {/* <Title text={'Prix d\'achat : ' + cost} variant='h5' /> */}
            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Coup Brute" variant='outlined' value={calculateBrutePrice()} />
            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled label="Prix base" variant='outlined' value={commande.priceBase} />

            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled={commande.isReduction} label="Reduction (%)" variant='outlined' value={commande.reduction} onChange={(e) => {
              setCommande(prev => ({ ...prev, reduction: parseFloat(e.target.value), price: commande.priceBase * (1 - parseFloat(e.target.value) / 100) }))

            }} />
            {/* <Switch sx={{ alignSelf: "center" }} value={commande.isReduction} onChange={(e) => { setCommande( prev => ({ ...prev, isReduction: e.target.checked})) }} ></Switch> */}
            <TextField sx={{ margin: "1rem", width: "40%" }} type='number' disabled={!commande.isReduction} label="Prix finale" variant='outlined' value={commande.price} />

            <Button variant='outlined' color='success' onClick={() => addCommande(commande, commandDetail)}>{add ? "Valider" : "Modifier"} la commande</Button>


          </Paper>
        </Box>
      </Box>


      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default CommandeWeapon;
