import React, { useEffect, useState } from 'react';
import {  Button, TableCell, TableRow } from '@mui/material';
import { add,  formatDuration, intervalToDuration } from 'date-fns';
import { craftWeapon, item } from '../types';
import { fr } from 'date-fns/locale';


function WeaponTableItem({ itemList, craft, onClick }: { itemList: item[], craft: craftWeapon, onClick: (id: number) => Promise<void> }) {

  // const [intervalRender, setIntervalRender] = useState<number>(1)
  const [test, setTest] = useState<number>(Math.random())


  useEffect(() => {
    const interval = setTimeout(() => {
      if (!craft.lastCrafted) return
      const nextCraft = add(craft.lastCrafted, { hours: craft.cooldown, seconds: 2 })
      // const dif = differenceInMinutes(nextCraft, new Date())
      // if (dif === 0) {
      //   const difSec = differenceInSeconds(nextCraft, new Date())
      //   if (difSec === 0) return setTest(Math.random())
      // }
      if (new Date() > nextCraft) return

      // if (dif > 1440) return

      // if (dif > 60 && dif < 1440) setIntervalRender(60 * 5)
      // else if (dif <= 60 && dif > 2) setIntervalRender(60)
      // else if (dif <= 2 && dif >= 1) setIntervalRender(10)
      // else if (dif < 1) setIntervalRender(1)

      setTest(Math.random())
    },  1000)

    return () => clearTimeout(interval)
  }, [test])

  const timeToNextCraft = (craft: craftWeapon) => {
    if (!craft.lastCrafted) return "Maintenant"
    const nextCraft = add(craft.lastCrafted, { hours: craft.cooldown })

    // if (new Date() <= nextCraft) return formatDistanceStrict(nextCraft, new Date(), { locale: fr, addSuffix: true, roundingMethod: "floor" })
      if (new Date() < nextCraft) return formatDuration(intervalToDuration({ start: new Date(), end: nextCraft}), {locale: fr, })

    return "Maintenant"
  }

  return (
    <>
      <TableRow key={Math.random()}>
        <TableCell>{itemList.find(item => item.id === craft.itemID)?.name}</TableCell>
        <TableCell>{craft.recipeWeapon.map(recipe => `${recipe.quantity}x ${itemList.find(item => item.id === recipe.itemID)?.name}`).join(", ")}</TableCell>
        <TableCell>{timeToNextCraft(craft)}</TableCell>
        <TableCell>
          <Button color='primary' variant='contained' disabled={craft.lastCrafted !== null && new Date() < add(craft.lastCrafted, { hours: craft.cooldown })} onClick={() => { onClick(craft.id) }}>Fabriquer</Button>
        </TableCell>
      </TableRow>
    </>
  );
}

export default WeaponTableItem;
