import React, { useEffect, useState } from 'react';
import './css/home.css';
import 'rsuite/DateRangePicker/styles/index.css';
import Header from '../components/header';
import { useNavigate } from 'react-router-dom';
import { formatAmount, intervalTime, removeDay } from '../utils';
import { itemTotal, price, priceWeaponCommande, statClient, statItem } from '../types';
import { Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, MenuItem, Checkbox, FormControl, InputLabel, ListItemText, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { DateRangePicker } from 'rsuite';
import { addDays, startOfYear, startOfMonth, startOfWeek, endOfDay } from 'date-fns';
import { DataGrid, GridColDef, GridRenderCellParams, GridRowsProp, GridToolbar } from '@mui/x-data-grid';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};




function Statistique() {
  let navigate = useNavigate()

  const [statInterval, setStatInterval] = useState({
    end: new Date(Date.now()),
    beginning: removeDay(new Date(Date.now()), 7),
    itemList: [1],
    typeList: ["PM", "Groupe"]
  })

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Qui ?',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<statClient>) => (
        <Box sx={{ color: "text.primary" }}>{params.row.client?.name}</Box>
      )
    },
    {
      field: 'quantity',
      headerName: 'Combien ?',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<statClient>) => (
        <Box sx={{ color: params.row.item.color ? params.row.item.color : "text.primary" }}>{formatAmount(params.row.quantity)}</Box>
      )
    },
    {
      field: 'poids',
      headerName: 'Quoi ?',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      resizable: false,
      renderCell: (params: GridRenderCellParams<statClient>) => (
        <Box sx={{ color: params.row.item.color ? params.row.item.color : "text.primary" }}>{params.row.item.name}</Box>
      )
    }
  ]

  const [itemList, setItemList] = useState<statItem[]>()

  const [statClient, setStatClient] = useState<statClient[]>()


  useEffect(() => {
    getItemList()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    getStatClient()
    const interval = setInterval(() => {
    }, intervalTime)

    return () => clearInterval(interval)
  }, [statInterval])

  const getStatClient = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/statistique/client', {
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors',
        method: "POST",
        credentials: "include",
        body: JSON.stringify(statInterval)
      })

      // setList([...list, 100])
      if (response.status === 400) return navigate("/discord")
      const data: statClient[] = await response.json();
      setStatClient(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  const getItemList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/client/price/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: price[] = await response.json();


      let itemListTemp: statItem[] = []

      for (let i = 0; i < data.length; i++) {
        itemListTemp.push({
          itemID: data[i].itemID,
          itemName: data[i].item.name,
          color: data[i].item.color
        })
      }
      const responseWeapon = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/sell/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const dataWeaponList: priceWeaponCommande[] = await responseWeapon.json();


      for (let i = 0; i < dataWeaponList.length; i++) {
        itemListTemp.push({
          itemID: dataWeaponList[i].itemID,
          itemName: dataWeaponList[i].item.name,
          color: dataWeaponList[i].item.color

        })
      }
      let idList: number[] = []
      for (let i = 0; i < itemListTemp.length; i++) {
        idList.push(itemListTemp[i].itemID)
      }

      setStatInterval(prev => ({ ...prev, itemList: idList }))
      setItemList(itemListTemp)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }


  const handleChange = (event: SelectChangeEvent<number[]>) => {
    const {
      target: { value },
    } = event;
    setStatInterval(prev => ({ ...prev, itemList: typeof value === "string" ? [] : value }))
  };

  const handleChangeType = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    setStatInterval(prev => ({ ...prev, typeList: typeof value === "string" ? [] : value }))
  };

  return (
    < >
      <Box sx={{ p: 2 }}>
        <Typography variant="h4" color='text.primary' sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
          Statistique
        </Typography>
      </Box>
      <Paper elevation={16} sx={{ p: 2, display: "flex", alignItems: "center" }}>
        <FormControl sx={{ m: 1, width: 200 }}>
          <InputLabel id="Item-List">Qui ?</InputLabel>
          <Select
            labelId="Item-List"
            id="Item-List"
            multiple
            value={statInterval.typeList}
            onChange={handleChangeType}
            input={<OutlinedInput label="Qui ?" />}
            renderValue={(selected) => selected.join(",")
            }
            MenuProps={MenuProps}
          >
            <MenuItem key={1} value={"PM"}>
              <Checkbox checked={statInterval.typeList.indexOf("PM") > -1} />
              <ListItemText primary={"Petite Main"} />
            </MenuItem>
            <MenuItem key={1} value={"Groupe"}>
              <Checkbox checked={statInterval.typeList.indexOf("Groupe") > -1} />
              <ListItemText primary={"Groupe"} />
            </MenuItem>

          </Select>
        </FormControl>
        <FormControl sx={{ m: 1, width: 300 }}>
          <InputLabel id="Item-List">Quoi ?</InputLabel>
          <Select
            labelId="Item-List"
            id="Item-List"
            multiple
            value={statInterval.itemList}
            onChange={handleChange}
            input={<OutlinedInput label="Quoi ?" />}
            renderValue={(selected) => selected.map((item) => {
              if (!itemList) return
              for (let i = 0; i < itemList.length; i++) {
                if (itemList[i].itemID === item) return itemList[i].itemName
              }
            }).join(", ")
            }
            MenuProps={MenuProps}
          >
            {itemList?.map((item) => (
              <MenuItem key={item.itemID} value={item.itemID} sx={{ color: item.color }}>
                <Checkbox checked={statInterval.itemList.indexOf(item.itemID) > -1} />
                <ListItemText primary={item.itemName} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <DateRangePicker ranges={
          [
            {
              label: '7 Derniers jours',
              value: [addDays(new Date(), -7), new Date()],
              placement: 'bottom'
            },
            {
              label: 'Depuis le debut de la semaine',
              value: [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()],
              placement: 'bottom'
            },
            {
              label: '30 Derniers jours',
              value: [addDays(new Date(), -30), new Date()],
              placement: 'bottom'
            },
            {
              label: 'Depuis le debut du mois',
              value: [startOfMonth(new Date()), new Date()],
              placement: 'bottom'
            },
            {
              label: 'Derniere année',
              value: [addDays(new Date(), -365), new Date()],
              placement: 'bottom'
            },
            {
              label: 'Depuis le debut de l\'année',
              value: [startOfYear(new Date()), new Date()],
              placement: 'bottom'
            },
            {
              label: 'Depuis toujours',
              value: [new Date(0), new Date()],
              placement: 'bottom'
            }
          ]
        }
          value={[
            statInterval.beginning,
            statInterval.end
          ]}
          onChange={e => {

            if (!e) return
            setStatInterval(prev => ({
              ...prev,
              beginning: e[0],
              end: endOfDay(e[1])

            }))
          }}
          appearance='subtle'
          style={{ width: 250, }}
          showHeader={false}
          label="Periode : "
          format='dd-MM-yyyy'
          isoWeek
        />
      </Paper>
      <br />

      {/* <Paper elevation={16} sx={{ p: 2 }}>
        <Table sx={{ width: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>Qui ?</TableCell>
              <TableCell>Combien ?</TableCell>
              <TableCell>Quoi ?</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {statClient?.map(stat => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell>{stat.client?.name}</TableCell>
                  <TableCell sx={{ color: "primary", fontWeight: "normal" }}>{stat.quantity}</TableCell>
                  <TableCell sx={{ color: stat.item.color, fontWeight: "normal" }}>{stat.item.name}</TableCell>

                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper> */}

      <Paper elevation={16} sx={{ p: 2, width: 900 }}>
        <Box sx={{ width: '100%', height:900 }}>
          <DataGrid
            // initialState={{
            //   density: 'compact',
            // }}
            getRowId={(row) => Math.random()}
            rows={statClient}
            pageSizeOptions={[15]}
            columns={columns}
            disableColumnMenu={true}
            slots={{ toolbar: GridToolbar }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            disableRowSelectionOnClick
            slotProps={{
              toolbar: {
                // showQuickFilter: true,
                printOptions: { disableToolbarButton: true },
                csvOptions: { disableToolbarButton: true }


              }
            }}
          />

        </Box>
      </Paper>
    </ >
  );
}

export default Statistique;
