import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { formatCurrency, intervalTime } from '../../utils';
import Title from '../../components/Title';
import { Snackbar, Alert, SnackbarOrigin, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { commande } from '../../types';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';


const anchorOrigin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' }

function CommandeList() {
  let navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const handleClose = () => setOpen(false)

  const [commandList, setCommand] = useState<commande[]>([])



  useEffect(() => {
    getCommandList()
    const interval = setInterval(() => {
      getCommandList()
    }, intervalTime)

    return () => clearInterval(interval)
  }, [])


  const getCommandList = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_URL + '/weapon/commande/list', {
        mode: 'cors',
        method: "GET",
        credentials: "include"
      })

      const data: commande[] = await response.json();


      setCommand(data)

    } catch (error) {
      console.error('Error get user data', error);
    }
  }

  return (
    <>
      <Title text='Liste des commandes' />


      <Paper elevation={16} sx={{ p: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ textAlign: "center" }}> Quand ? </TableCell>
              <TableCell sx={{ textAlign: "center" }}>Qui ?</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Prix a payé</TableCell>
              <TableCell sx={{ textAlign: "center" }}>Commande prête</TableCell>
              <TableCell sx={{ textAlign: "center" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {commandList?.map(commande => {
              return (
                <TableRow key={Math.random()}>
                  <TableCell sx={{ textAlign: "center" }}> {new Date(commande.createdAt).toLocaleString('FR-fr', { timeZone: 'Europe/Paris' })} </TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{commande.client?.name}{commande.client?.type ==="PM" ? ` (${commande.client.number})` : ""}</TableCell>
                  {/* <TableCell sx={{ textAlign: "center" }}>{commande.reduction}%</TableCell> */}
                  <TableCell sx={{ textAlign: "center" }}>{formatCurrency(commande.price)}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>{commande.ready ? <CheckOutlinedIcon sx={{color: "green"}}/> : <CloseOutlinedIcon sx={{color:"red"}}/>}</TableCell>
                  <TableCell sx={{ textAlign: "center" }}>
                    <Button component={Link} to={`/weapon/commande?id=${commande.id}`}>Voir la commande</Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </Paper>

      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={anchorOrigin}>
        <Alert severity="success" variant='filled'>Le mouvement a été enregistré.</Alert>
      </Snackbar>
    </>
  );
}

export default CommandeList;
